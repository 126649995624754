type ObjectInterfaceNumberString = {
  [key: number]: string;
};

type ObjectInterfaceStringNumber = {
  [key: string]: number;
};

type ObjectInterfaceStringString = {
  [key: string]: string;
};

const NAME_MONTH: ObjectInterfaceStringNumber = {
  января: 0,
  февраля: 1,
  марта: 2,
  апреля: 3,
  мая: 4,
  июня: 5,
  июля: 6,
  августа: 7,
  сентября: 8,
  октября: 9,
  ноября: 10,
  декабря: 11,
};

const COUNT_MONTH: ObjectInterfaceNumberString = {
  0: 'января',
  1: 'февраля',
  2: 'марта',
  3: 'апреля',
  4: 'мая',
  5: 'июня',
  6: 'июля',
  7: 'августа',
  8: 'сентября',
  9: 'октября',
  10: 'ноября',
  11: 'декабря',
};

const ROUTES_LIST: ObjectInterfaceStringString = {
  messages: 'Сообщения',
  deals: 'Мои заказы',
  auction: 'Аукцион',
  stats: 'Статистика',
  finance: 'Финансы',
  helpInfo: 'Справочная информация',
  studing: 'Обучение',
  ondate: 'На дату',
  notification: 'Уведомления',
  files: 'Файлы от клиента',
  yourfiles: 'Ваши файлы',
  statsdeals: 'Статистика заказов',
  rating: 'Рейтинг',
  historyOperation: 'История операций',
  historyTran: 'История транзакций',
  profile: 'Профиль',
  qualification: 'Квалификация',
  auctionSettings: 'Настройки аукциона',
  requisites: 'Реквизиты',
  notifications: 'Уведомления',
  search: 'Поиск',
};

const LIST_DATE = [
  { name: 'За все время', value: 'all' },
  { name: 'За месяц', value: 'month' },
  { name: 'За год', value: 'year' },
  { name: 'За неделя', value: 'week' },
  { name: 'За день', value: 'day' },
];

const MONTH: ObjectInterfaceNumberString = {
  0: '01',
  1: '02',
  2: '03',
  3: '04',
  4: '05',
  5: '06',
  6: '07',
  7: '08',
  8: '09',
  9: '10',
  10: '11',
  11: '12',
};

const COLORS = [
  '#10c9d3',
  '#00246E',
  '#FFBB4F',
  '#FF7576',
  '#4318FF',
  '#8F9BBA',
];

const settings = {
  VITE_MASTER_URL: 'https://wizard24.ru',
  VITE_TEST_MASTER_URL: 'https://test.wizard24.ru',
  VITE_MASTER_MIRROR_URL: 'https://mirror.wizard24.ru',
  VITE_MASTER_MIRROR2_URL: 'https://mirror2.wizard24.ru',
  VITE_STAGE_URL: 'https://stage.wizard24.ru',
  VITE_API_MASTER_URL: 'https://v2.wizard24.ru/api',
  VITE_API_STAGE_URL: 'https://stage.v2.wizard24.ru/api',
};

export {
  NAME_MONTH,
  COUNT_MONTH,
  ROUTES_LIST,
  LIST_DATE,
  MONTH,
  COLORS,
  settings,
};
